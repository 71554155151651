import { useState } from 'react';
import { Link } from 'react-router-dom';

type ProjectCardProps = {
	slug: string;
	name: string;
	description: string;
	image: string;
};

export default function ProjectCard({
	slug,
	name,
	description,
	image,
}: ProjectCardProps) {
	const [loading, setLoading] = useState(false);

	if (loading) {
		return (
			<a className="flex flex-col cursor-pointer border-transparent bg-zinc-50 m-2 rounded-sm border-2 hover:border-zinc-200 md:flex-row">
				<div className="h-48 w-full rounded-sm m-2 animate-pulse bg-zinc-100 md:max-w-64" />
				<div className="p-2 w-full">
					<div className="w-full h-4 bg-zinc-100 animate-pulse my-2"></div>
					<div className="w-full h-4 bg-zinc-100 animate-pulse my-2"></div>
				</div>
			</a>
		);
	}

	return (
		<Link
			className="flex flex-col cursor-pointer border-transparent bg-zinc-50 m-2 rounded-sm border-2 hover:border-zinc-200 md:flex-row"
			to={'/projects/' + slug}
		>
			<img
				src={image}
				onLoad={() => setLoading(false)}
				className="h-48 rounded-sm m-2 object-cover md:max-w-64"
			/>
			<div className="p-4">
				<h3 className="font-bold text-xl mb-4">{name}</h3>
				<p className="text-zinc-600 leading-relaxed">{description}</p>
			</div>
		</Link>
	);
}

import { Link, useParams } from 'react-router-dom';

import projects from '../data/projects.json';
import { FaChevronRight, FaGithub, FaPlay } from 'react-icons/fa';

export default function ProjectDetails() {
	const { slug } = useParams();
	const project = slug ? projects.works.find((p) => p.slug === slug) : null;

	if (!project) {
		return (
			<div className="flex flex-col items-center justify-center h-screen gap-8">
				<h1 className="text-8xl">404</h1>
				<h3 className="text-xl">Project not found</h3>
				<a href="/" className="text-blue-500 underline">
					Go back home
				</a>
			</div>
		);
	}

	return (
		<div className="min-h-screen w-full mx-auto mt-24 px-6 md:w-3/4">
			<div className="flex my-4 text-zinc-500 font-bold text-md items-center gap-2 underline ">
				<Link className="" to={'/projects'}>
					Projects
				</Link>
				<FaChevronRight /> {project.title}
			</div>
			<div className="flex flex-col gap-4">
				<img
					src={require(`../assets/${project.banner}`)}
					className="h-96 w-full object-cover"
					alt="A screenshot of the project as a banner"
				/>
				<div className="flex flex-col justify-between gap-6 md:flex-row md:gap-0">
					<div>
						<h3 className="text-2xl font-bold lg:text-4xl">{project.title}</h3>
						<p className="text-2xl text-zinc-500">{project.date}</p>
					</div>
					<div className="flex flex-row gap-2">
						{project.repoLink !== '#' && project.projectLink !== '#' && (
							<a
								className="flex flex-row items-center gap-2 bg-zinc-50 text-zinc-700 px-4 py-2 rounded-sm justify-self-end text-md hover:bg-zinc-100"
								href={project.repoLink}
								target="_blank"
								rel="noreferrer"
							>
								Project Repository
								<FaGithub />
							</a>
						)}
						{project.projectLink !== '#' && (
							<a
								className="flex flex-row items-center gap-2 bg-zinc-50 text-zinc-700 px-4 py-2 rounded-sm justify-self-end text-md hover:bg-zinc-100"
								href={project.projectLink}
								target="_blank"
								rel="noreferrer"
							>
								Project Demo
								<FaPlay />
							</a>
						)}
					</div>
				</div>
				<div className="mt-12">
					<h1 className="text-3xl font-bold my-4">Overview</h1>
					<p className="text-zinc-800 my-4 leading-loose text-lg">
						{project.content.overview.text}
					</p>
					<img
						src={require(`../assets/${project.content.overview.image}`)}
						alt="An overview screenshot of the project"
					/>
					<p className="my-4 leading-loose">{project.content.tech.join(' - ')}</p>
					<h1 className="text-3xl font-bold my-4">Results</h1>
					<p className="my-4 leading-loose">{project.content.results.text}</p>
					<img
						src={require(`../assets/${project.content.results.image}`)}
						alt="A screenshot of the final result"
					/>
				</div>
			</div>
		</div>
	);
}

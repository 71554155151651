import ProjectCard from '../components/ProjectCard';
import projects from '../data/projects.json';

export default function Projects() {
	return (
		<div className="bg-zinc-100 h-screen">
			<div className="grid grid-cols-1 lg:grid-cols-2">
				{projects.works.map((project) => (
					<ProjectCard
						key={project.title}
						name={project.title}
						description={project.description}
						image={require(`../assets/${project.banner}`)}
						slug={project.slug}
					/>
				))}
			</div>
		</div>
	);
}

import { Link } from 'react-router-dom';

function Intro() {
	return (
		<>
			<div className="flex relative flex-col px-4 h-[80vh] items-center justify-center z-50 select-none">
				<img
					src={require('../assets/brandon.jpg')}
					alt="Brandon"
					className="w-48 h-48 rounded-full"
				/>
				<h1 className="text-5xl my-4">I'm Brandon Waine.</h1>
				<h3 className="text-xl text-zinc-700">
					I'm a software engineer and creative developer.
				</h3>
				<Link
					to="/projects"
					className="bg-[#e91e63] text-white px-4 py-2 rounded-sm justify-self-end tracking-wide mt-20 select-auto border border-[#e91e63] hover:bg-transparent hover:text-[#e91e63]"
				>
					View my work
				</Link>
			</div>
		</>
	);
}

export default Intro;

import React from 'react';
import { FaGithub, FaLinkedin, FaMailBulk } from 'react-icons/fa';

export default function Contact() {
	return (
		<div className="flex flex-col h-lvh gap-8 justify-center items-center">
			<h1 className="text-5xl">Hello! 👋</h1>
			<h1 className="text-3xl leading-relaxed text-center">
				I'm currently looking for new{' '}
				<span className="underline"> entry level software engineering</span>{' '}
				opportunities.
			</h1>
			<h1 className="text-2xl text-zinc-800">Get in touch</h1>
			<p className="text-zinc-700 text-lg">email: brandonwaine@outlook.com</p>
			<div className="flex flex-row gap-4">
				<a
					href="https://www.linkedin.com/in/brandonwaine/"
					target="_blank"
					rel="noreferrer"
					className="flex flex-row items-center gap-2 bg-zinc-50 text-zinc-700 px-4 py-2 rounded-sm justify-self-end text-md hover:bg-zinc-100"
				>
					LinkedIn
					<FaLinkedin />
				</a>
				<a
					href="https://github.com/brandoncwaine"
					target="_blank"
					rel="noreferrer"
					className="flex flex-row items-center gap-2 bg-zinc-50 text-zinc-700 px-4 py-2 rounded-sm justify-self-end text-md hover:bg-zinc-100"
				>
					Github
					<FaGithub />
				</a>
				<a
					href="mailto:brandonwaine@gmail.com"
					target="_blank"
					rel="noreferrer"
					className="flex flex-row items-center gap-2 bg-zinc-50 text-zinc-700 px-4 py-2 rounded-sm justify-self-end text-md hover:bg-zinc-100"
				>
					Email
					<FaMailBulk />
				</a>
			</div>
		</div>
	);
}

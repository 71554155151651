import { FaChevronRight } from 'react-icons/fa';
import projects from '../data/projects.json';
import ProjectCard from './ProjectCard';

export default function RecentWork() {
	return (
		<section className="px-4">
			<div className="grid grid-cols-1 lg:grid-cols-2">
				{projects.works.map((project) => (
					<ProjectCard
						key={project.slug}
						slug={project.slug}
						name={project.title}
						image={require(`../assets/${project.banner}`)}
						description={project.description}
					/>
				))}
				<a
					href="/projects"
					className="flex items-center justify-center cursor-pointer border-transparent h-48 bg-zinc-50 m-2 rounded-sm border-2 hover:border-zinc-200 lg:flex-row"
				>
					<h3 className="flex flex-row gap-2 items-center text-xl text-zinc-500">
						View All
						<FaChevronRight />
					</h3>
				</a>
			</div>
		</section>
	);
}

import Intro from '../components/Intro';
import RecentWork from '../components/RecentWork';

export default function Home() {
	return (
		<>
			<Intro />
			<RecentWork />
		</>
	);
}

import { useState } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { Link, Outlet } from 'react-router-dom';

export default function Header() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<header>
			<nav>
				<div className="flex justify-between items-center flex-row py-4 px-4">
					<a href="/">
						<img
							src={require('../assets/logo.png')}
							alt="logo"
							width={32}
							height={32}
						/>
					</a>
					<button onClick={() => setIsOpen(!isOpen)} className="sm:hidden">
						{isOpen ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="30"
								height="30"
								viewBox="0 0 30 30"
							>
								<path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="30"
								height="30"
								viewBox="0 0 30 30"
							>
								<path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
							</svg>
						)}
					</button>
					<ul className="hidden sm:flex">
						<li className="flex flex-row gap-8 mx-8">
							<Link to="/" className="text-zinc-600 text-base">
								Home
							</Link>
							<Link to="/projects" className="text-zinc-600 text-base">
								Projects
							</Link>
							<Link to="/contact" className="text-zinc-600 text-base">
								Contact
							</Link>
						</li>
						<li className="flex flex-row gap-2">
							<a
								className="text-2xl text-zinc-800 font-bold"
								href="https://uk.linkedin.com/in/brandonwaine"
								target="_blank"
							>
								<FaLinkedin />
							</a>
							<a
								className="text-2xl text-zinc-800 font-bold"
								href="https://github.com/brandoncwaine"
								target="_blank"
							>
								<FaGithub />
							</a>
						</li>
					</ul>
				</div>
				<ul
					className={`${
						isOpen ? 'flex' : 'hidden'
					} absolute w-screen h-screen z-50 bg-zinc-700 sm:hidden`}
				>
					<li className="flex flex-col items-center justify-center w-full">
						<Link to="/" className="w-full py-6 px-4 text-white text-2xl text-center">
							Home
						</Link>
						<Link to="/" className="w-full py-6 px-4 text-white text-2xl text-center">
							Work
						</Link>
						<Link to="/" className="w-full py-6 px-4 text-white text-2xl text-center">
							Contact
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}
